import React from 'react'
import './services.css'
import Header from '../header/Header.jsx'
import PeintureInterieure from '../../assets/cuisine-2.2.jpg'
import PeintureExterieure from "../../assets/patio-1.jpg"
import PoseDePapierPeint from '../../assets/salle-de-bain-5.jpg'
import ServiceDeDecoration from '../../assets/salon-1.jpg'
import { Helmet } from 'react-helmet-async'

const Services = () => {
  return (
    <section id='services'> 
      <Helmet>
        <title> Tous les services offerts | Peinture Grande Distinction</title>
        <meta name="description" content="Que ce soit pour un service de peinture, de décoration ou de pose de papier peint, nous sommes prêts à donner un service clé en main. Estimation 100 % Gratuite!"></meta>
        <link rel="canonical" href="/services" />
      </Helmet>
      <Header/>
      <div className='container services_container'>
         <article className='Nos service'>
           <h2>Peinture intérieure</h2>
           <div className='service-image'>
                <img src={PeintureInterieure} alt="Peinture intérieur dans une cuisine" />
           </div>
         </article>
         <article className='service'>
           <h2>Peinture extérieure</h2>
           <div className='service-image'>
                <img src={PeintureExterieure} alt="Peinture extérieur sur un patio" />
           </div>
         </article>
         <article className='service'>
           <h2>Pose de papier peint</h2>
           <div className='service-image'>
                <img src={PoseDePapierPeint} alt="Pose de papier peint dans une salle de bain" />
           </div>
         </article>
         <article className='service'>
           <h2>Service de décoration</h2>
           <div className='service-image'>
                <img src={ServiceDeDecoration} alt="Décoration dans un salon" />
           </div>
         </article>
         </div>
    </section>
  )
}

export default Services