import ServicePeintureInterieure from '../../../assets/cuisine-2.2.jpg'
import ServicePeintureExterieure from '../../../assets/patio-1.jpg'
import ServicePosePapierPeint from '../../../assets/salle-de-bain-5.jpg'
import ServiceDecoration from '../../../assets/salon-1.jpg'
import './service.css'
import React from 'react'

const data = [
    {
        id:1,
        service:"Peinture Intérieure",
        img:ServicePeintureInterieure,
        alt : "Peinture intérieure dans une cuisine"
    },
    {
        id:2,
        service:"Peinture Extérieure",
        img:ServicePeintureExterieure,
        alt : "Peinture extérieure sur un patio"
    },
    {
        id:3,
        service:"Pose De Papier Peint",
        img: ServicePosePapierPeint,
        alt : "Pose de papier peint dans une salle de bain"
    },
    {
        id:4,
        service:"Service De Décoration",
        img: ServiceDecoration,
        alt : "Décoration dans un salon"
    }
  ]

const Service = () => {
  return (
    <section id='service'> 
    <div className='service_container'>
        {data.map(({id,img,service,alt}) => {
           return(    
              <div key={id} className="service-content">
                  <div className="service-img">
                       <img src={img} alt={alt}/>
                  </div>       
                  <div className="service-number">
                       <p>{id}</p>
                  </div> 
                  <div className="service-name">
                       <p>{service}</p>
                  </div> 
              </div>                                 
           )
        })
       }
    </div>    
    </section>
  )
}

export default Service