import { useState } from "react"
import "./nav.css"
import {AiOutlineMenu} from 'react-icons/ai'

export default function Nav() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav className="navigation">
      <a href="/" className="company-name home">
         PEINTURE GRANDE DISTINCTION
      </a>
      <AiOutlineMenu
        className="nav-icon"
        
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
        >
      </AiOutlineMenu>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
            <li><a href="/" >Accueil</a></li>
            <li><a href="/equipe">Notre équipe</a></li>
            <li><a href="/services" >Services</a></li>
            <li><a href="/realisations">Réalisations</a></li>
            <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
    </nav>
  );
}