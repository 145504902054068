import React from 'react'
import './footer.css'
import RequestEstimation  from './requestEstimation/RequestEstimation'
import {BsFacebook} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
        <RequestEstimation/>
        <div className='container footer_container'>
        <h3 className='company-name footer'>PEINTURE GRANDE DISTINCTION</h3>    
        <div className='footer-lists'> 
        <article className='footer-lists'>
            <div className='footer-socials'>
            <h3>Suivez-nous </h3>
            <a href='https://www.facebook.com/peinturegrandedistinction' target='_blank' rel="noreferrer"  className='icon footer-icon'><BsFacebook/></a>
            </div>
        </article>
        <article className='footer-list'>
        <h3>Contactez-nous</h3>
        <ul className='permalinks'>
            <li>514 821 3490</li>
            <li className='site-email'>info@peinturegrandedistinction.com</li>
        </ul>
        </article>
        <article className='footer-list'>
        <h3>Liens rapides</h3>    
        <ul className='permalinks'>
            <li><a href="/">Accueil</a></li>
            <li><a href="/equipe">Notre équipe</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/realisations">Réalisations</a></li>
            <li><a href="/contact">Contact</a></li>
        </ul>
        </article>
        <article className='footer-list'>
        <h3>Nos services</h3>    
        <ul className='permalinks'>
            <li>Peinture intérieure</li>
            <li>Peinture extérieure</li>
            <li>Pose de papier peint</li>
            <li>Service de décoration</li>
        </ul>
        </article>
        </div>
        
        <div className="footer-copyright">
           <div className='footer-copyright-name-website'>
                <p>&copy; 2022 Peinture Grande Distinction</p>
            </div>
            <div className='footer-copyright-contact-website'>
                <p><a href="https://option1web.com/contact" target='_blank' rel="noreferrer">Créé Par: Option 1 Web</a></p>
            </div>
        </div>
        </div>
    </footer>
  )
}

export default Footer