import React from 'react';

export const NotFoundPage = () => {
    return (
        <div>
            <h1 style={{textAlign:"center"}} >Oups...Cette page n'existe pas</h1>
            <p style={{textAlign:"center"}}>
              <a href="/">Retouner à la page principale</a>
            </p>
        </div>
    )
}

export default NotFoundPage

