import React, { Suspense, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import './index.css'

const root = createRoot(document.getElementById("root"))
root.render
  (
    <StrictMode>
      <Suspense fallback="...loading">
            <HelmetProvider>
                <App />
            </HelmetProvider>
      </Suspense>
    </StrictMode>
  )