import React from 'react'
import './presentation.css'
import {BiMailSend} from 'react-icons/bi'
import {BsFillTelephonePlusFill} from 'react-icons/bs'

const Presentation = () => {
  return (
    <section id='presentation'>
    <div className='container presentation_container'>
            <div className='presentation-content'>
                 <h1 className='website-title'>Peinture Grande Distinction</h1>
                 <h2>Pour Mettre De La Couleur Dans Votre Vie !</h2>
                 <p>Depuis déjà 2015 Peinture Grande Distinction  est une entreprise en peinture résidentielle  qui décore, peinture et met de la couleur  dans votre vie. Peinture Grande Distinction  se déplace sur  tout le territoire de Vaudreuil-Soulanges, l’Ouest de l’Ile de Montréal ainsi que le grand Salaberry-de-Valleyfield.<br/><br/> N’hésitez-pas à nous contacter et nous confier l’un de vos futurs  projets.</p>
            </div>
            <div className='presentation-contact-options'>
            <div className='presentation-contact-option'>
                <div><BsFillTelephonePlusFill  className='icon presentation-contact-option-icon'/></div>
                 <div className='presentation-contact-option-content'>
                      <p className='presentation-contact-option-content1'>Appelez-Nous</p>
                      <p>(514) 821-3490</p>
                 </div>
              </div>
              <div className='presentation-contact-option'>
                 <div><BiMailSend className='icon presentation-contact-option-icon'/></div>
                 <div className='presentation-contact-option-content'>
                      <p className='presentation-contact-option-content1'>Envoyez-Nous Un Courriel</p>
                      <p>info@peinturegrandedistinction.com</p>
                 </div>
              </div>
            </div> 
    </div>
    </section>
  )
}

export default Presentation