import React from 'react'
import './requestEstimation.css'
// import call from 'react-native-phone-call'

const RequestEstimation = () => {
  return (
    <section id='request-estimation'>
      <div className='request-estimation-overall'>
      <div className='container request-estimation-container'>
           <h2>Besoin D'une Estimation ?</h2>
           <div className='request-estimation_btn'>
              <a href="/contact" className='btn-type2'>Obtenez une estimation gratuite</a>
              <a href="tel:+1(514)-821-3490" className='btn-type1' target="_blank" rel="noreferrer">Appelez-nous</a>
           </div>
      </div>
      </div>
    </section>
  )
}

export default RequestEstimation