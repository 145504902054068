import React from 'react';
import './homeHeader.css'

export const HomeHeader = ({title, subTitle}) => {
    return (
        <header>
            <div  className='home-header-image'>
            </div>
        </header>
    )
}

export default HomeHeader