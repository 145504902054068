import React from 'react'
import './experience.css'
import {BsFillTrophyFill} from 'react-icons/bs'
import {HiOutlineBadgeCheck} from 'react-icons/hi'
import {MdRoomService} from 'react-icons/md'

const Experience = () => {
  return (
    <section id='experience'>
      <div className='container experience_container'>
           <div className='experience-titles'>
               <h2>Les Atouts Qui Nous Distinguent</h2>
          </div>
           <div className='experience-lists'>
                    <article className='experience-item'>
                          <BsFillTrophyFill className='icon experience-icon'/>
                          <h3>Haut de gamme à prix abordable</h3>
                          <p>Nous tenons à vous donner le meilleur rapport qualité-prix possible.</p>
                    </article>
                    <article className='experience-item'>
                          <HiOutlineBadgeCheck className='icon experience-icon'/>
                          <h3>Soucis du détail</h3>
                          <p>Notre équipe fournit un travail professionnel et minutieux afin de répondre à vos attentes.</p>
                    </article>
                    <article className='experience-item'>
                          <MdRoomService className='icon experience-icon'/>
                          <h3>Offre un service conseil</h3>
                          <p>Nous travaillons en étroite collaboration avec des Designer qui combinent volontiers leur expertise à la nôtre.</p>
                    </article>
               </div>
      </div>
    </section>
  )
}

export default Experience