import React from 'react'
import  Presentation from './presentation/Presentation'
import Service from './service/Service'
import Experience from './experience/Experience'
import Testimonials from './testimonials/Testimonials'
import Header from './homeHeader/HomeHeader'

const Home = () => {
  return (
    <section id='home'>
      <Header/>
      <Presentation/>
      <Service/>
      <Experience/>
      <Testimonials/>
    </section>
  )
}

export default Home