import React from 'react'
import './achievements.css'
import Header from '../header/Header.jsx'
import Image1 from '../../assets/cuisine-1.jpg'
import Image2 from '../../assets/salle-de-bain-1.jpg'
import Image3 from '../../assets/salon (papier peint)-2.jpg'
import Image4 from '../../assets/salon-1.jpg'
import Image5 from '../../assets/entree-1.jpg'
import Image6 from '../../assets/salle-de-bain-3.jpg'
import Image7 from '../../assets/salle-a-manger-1.jpg'
import Image8 from '../../assets/bureau-1.jpg'
import Image9 from '../../assets/salle-de-bain-4.jpg'
import Image10 from '../../assets/patio-1.jpg'
import Image11 from '../../assets/salle-de-bain-2.jpg'
import Image12 from '../../assets/cuisine-2.1.jpg'
import Image13 from '../../assets/salon-3.jpg'
import Image14 from '../../assets/patio-2.jpg'
import Image15 from '../../assets/salle-de-bain-5.jpg'
// import Swiper core and required modules
import { Navigation} from 'swiper';
import { Helmet } from 'react-helmet-async'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const data = [
  {
   image:Image1,
   alt : "Peinture intérieure dans une cuisine"
  },
  {
    image:Image2,
    alt : "Pose de papier peint dans une salle de bain"
  },
  {
    image:Image3,
    alt : "Peinture intérieure dans une cuisine"
  },
  {
    image:Image4,
    alt : "Décoration dans un salon"
  },
  {
    image:Image5,
    alt : "Peinture intérieur à l'entrée de la maison"
  },
  {
    image:Image6,
    alt : "Pose de papier peint dans une salle de bain"
  },
  {
    image:Image7,
    alt : "Peinture intérieure dans une cuisine"
  },
  {
    image:Image8,
    alt : "Peinture intérieure dans un bureau"
  },
  {
    image:Image9,
    alt : "Peinture intérieure dans une salle de bain"
  },
  {
    image:Image10,
    alt : "Peinture extérieure sur un patio"
  },
  {
    image:Image11,
    alt : "Peinture intérieure dans une salle de bain"
  },
  {
    image:Image12,
    alt : "Peinture intérieure dans une cuisine"
  },
  {
    image:Image13,
    alt : "Peinture intérieure dans un salon"
  },
  {
    image:Image14,
    alt : "Peinture extérieure sur un patio"
  },
  {
    image:Image15,
    alt : "Pose de papier peint dans une salle de bain"
  }
]

const Achievements = () => {
  return (
    <section id='achievements'>
       <Helmet>
               <title>Découvrez nos réalisations en peinture résidentielle | Peinture Grande Distinction</title>
                <meta name="description" content="Regardez nos plus beaux projets de peinture résidentielle réalisés à travers Vaudreuil-Soulanges, l'Ouest de Montréal et Salaberry-de-Valleyfield"></meta>
                <link rel="canonical" href="/realisations" />
      </Helmet>
      <Header/>
      <Swiper className='container achievements_container'
          // install Swiper modules
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView={1}
      navigation>
        {
          data.map(({image, alt}, index)=>{
             return(
              <SwiperSlide key= {index} className='achievement'>
              <div className='achievement-image'>
                   <img src={image} alt={alt} />
              </div>     
               </SwiperSlide>
             )    
           })
        }
      </Swiper> 
    </section>
  )
}

export default Achievements
