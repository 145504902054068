import React from 'react'
import './team.css'
import Owner from '../../assets/photo-LynePayment-1.jpg'
import Employee1 from '../../assets/photo-CynthiaLeger-1.jpg'
import Header from '../header/Header.jsx'
import { Helmet } from 'react-helmet-async'

const Team = () => {
  return (

    <section id='team'>  
           <Helmet>
              <title>Notre Équipe | Peinture Grande Distinction</title>
                <meta name="description" content="Apprenez-en plus sur notre équipe de professionnels expérimentés. Peinture Grande Distinction s'assure de réaliser des travaux de qualité."></meta>
                <link rel="canonical" href="/equipe" />
           </Helmet> 
           <Header/>
           <div class="container equipe_container">
                         <div class="equipe">
                        <div class="portrait">
                          <img src={Owner} alt="Lyne Payment, propriétaire de Peinture Grande Distinction" class="img-responsive"/>
                        </div>
                        <div class="text-side">
                          <div class="nom">
                            <h3>Lyne Payment</h3>
                          </div>
                          <div class="desc">
                            <h4>Entrepreneure peintre - Conseillère en décoration - Installatrice en pose de papier peint</h4>
                          </div>
                          <div class="text-box">
                            <p>Lyne Payment propriétaire peintre a également une riche expérience en constructions domiciliaires résidentielles.<br/><br/>Sa passion est la décoration. Elle offre un service personnalisé  à tous ses clients et un « clef en main ». Elle et son équipe vous accompagneront dans votre futur projet qu’il soit en peinture, teinture, pose de papier peint et murale ou encore de conseils en décoration.</p>
                          </div>
                        </div>
                      </div>
                                            <div class="equipe">
                        <div class="portrait">
                          <img src={Employee1} alt="Cynthia Léger, membre de l'équipe de Peinture Grande Distinction" class="img-responsive"/>
                        </div>
                        <div class="text-side">
                          <div class="nom">
                            <h3>Cynthia Léger</h3>
                          </div>
                          <div class="desc">
                            <h4>Peintre</h4>
                          </div>
                          <div class="text-box">
                            <p></p>
                          </div>
                        </div>
                      </div>
                                      </div>
    </section>
  )
}

export default Team