import React from 'react'
import {useRef,  useState} from 'react'
import emailjs from '@emailjs/browser'
import ReCAPTCHA from 'react-google-recaptcha'
import './contact.css'
import {BiMailSend} from 'react-icons/bi'
import {BsFillTelephonePlusFill} from 'react-icons/bs'
import Header from '../header/Header.jsx'
import {Helmet} from 'react-helmet-async'

const Contact = () => {
  const refCaptcha = useRef();
  const form = useRef();
  const [error, setError]=useState();
  const sendEmail = (e) => {
    setError();
    e.preventDefault();

    const captchaValue = refCaptcha.current.getValue(); // <- `getValue()` from the instantiated refCaptcha

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_ID)
      .then((result) => {
          setError(1);
          console.log(result.text);
      }, (error) => {
          setError(2);
          console.log(error.text);
      });
      e.target.reset()  // Efface le formulaire après l'envoie
  }

  return (
    <section id='contact'>
      <Helmet>
          <title>Contactez-nous | Peinture Grande Distinction</title>
          <meta name="description" content="Communiquez dès maintenant avec nous pour discuter de vos projets de peinture résidentielle. Vous aurez une estimation rapide et gratuite"></meta>
          <link rel="canonical" href="/contact" />
      </Helmet> 
      <Header/>
      <div className='container contact_container'>
       <h2>Demander Une Estimation Gratuite</h2>
       <div className='container contact_contain-without-title'>
           <div>
           <p>Contactez-nous pour avoir une soumission gratuite! Vous n’avez qu’à remplir notre formulaire et nous vous contacterons rapidement. </p>
            <div className='contact_options'>
              <a className='contact_option' href="tel:15148213490" target="_blank" rel="noreferrer">
                 <BsFillTelephonePlusFill  className='icon contact_option-icon'/>
                 <p>Appelez-Nous</p>
                 <p>(514) 821-3490</p>
              </a>
              <a className='contact_option' href="mailto:info@peinturegrandedistinction.com" target="_blank" rel="noreferrer">
                 <BiMailSend className='icon contact_option-icon'/>
                 <p> Envoyez-Nous Un Courriel</p>
                 <p>info@peinturegrandedistinction.com</p>
              </a>
            </div>  
            </div> 
           <form ref={form}  onSubmit={sendEmail}>
              <p>Nom complet</p>
              <input type="text" name='name' required/>
              <p>Email</p>
              <input type="email" name='email' required/>
              <p>Sujet</p>
              <input type="text" name='subject' required/>
              <p>Commentaire ou message</p>
              <textarea name="message" rows="7" required></textarea>
              <ReCAPTCHA ref={refCaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
              <small className={error === 1?'':'message-confirmation'}>Envoyé</small>
              <small className={error === 2?'':'message-error'}>Erreur: Le formulaire n'a pas pu être envoyé. Veuillez nous contacter directement par courriel</small>
              <button type='submit' className='btn-type1'>Envoyer</button>
            </form>
          </div>
          </div>
    </section>
  )
}

export default Contact