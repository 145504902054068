import React from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Nav from './components/nav/Nav'
import Home from './components/home/Home'
import Services from './components/services/Services'
import Achievements from './components/achievements/Achievements'
import Contact from './components/contact/Contact'
import Team from './components/team/Team'
import Footer from './components/footer/Footer'
import NotFoundPage from './components/notfoundpage/NotFoundPage'

const App = () => {
  return (
    <BrowserRouter>
        <div className="App">
        <Nav/>
        <Routes>
              <Route exact path='/' element={< Home />}></Route>
              <Route exact path='/equipe' element={< Team />}></Route>
              <Route exact path='/services' element={< Services />}></Route>
              <Route exact path='/realisations' element={< Achievements />}></Route>
              <Route exact path='/contact' element={< Contact />}></Route>
              <Route path="*" element={< NotFoundPage />}></Route>
       </Routes>
       <Footer/>
       </div>
    </BrowserRouter>
);
}

export default App
