import React from 'react'
import './testimonials.css'
import { FaStar } from "react-icons/fa";

// import Swiper core and required modules
import {Autoplay,Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
   name: 'Michele Derozario',
   rating:5,
   review: '« Lyne did an excellent job painting my bathrooms. Very meticulous! I am very satisfied with her work. Would definitely recommend her! » ',
  },
  {
   name: 'Cynthia Leger',
   rating:5,
   review: '« Great employer and very good paint »',
  },
  {
   name: 'Louise Frigon',
   rating:5,
   review: "« Nous avons fait peindre notre salon cuisine et passage. Nous sommes extrêmement satisfaits du travail que Lyne a fait. Nous avons apprécié sa ponctualité, son savoir-faire, sa bonne compréhension de ce que nous avons demandé et les suggestions qu'elle nous a données pour améliorer le projet. Nous la recommandons fortement, car nous trouvons qu'elle est l'un des meilleurs peintres que nous n’ayons jamais eus. »", 
  },
  {
    name: 'Jacinthe Lévesque',
    rating:5,
    review:"« Très bonne expérience !!! Disponible et honnête pour répondre à nos questions... Travail de qualité, ponctualité et Fiabilité... Je vais certainement faire affaire à nouveau avec Mme Payment pour d'autres travaux dans le futur !!! Je la recommande et le prix demandé est très raisonnable. Aussi, elle a aidé en faisant de petits extras sans que je lui demande...Apprécié 😊  »", 
   },
   {
    name: 'Félix Gauthier',
    rating:5,
    review: "« Mon expérience est très positive. Je suis entièrement satisfait. J'ai reçu plus que ce à quoi je m'attendais. De plus, Lyne a replacé tous mes meubles, mes tableaux et etc pour le meilleur. J'ai reçu plein de compliments de tous mes amis sur mon nouveau décor. Les travaux ont été exécutés dans le temps et budget prévus. Ça a été au-delà de mes espérances. »", 
   },
   {
    name: 'Sandra et Éric',
    rating:5,
    review: "« Wow ! Quel beau travail ! Merci à toi et à ton équipe pour avoir fait de notre nouvelle demeure NOTRE nouveau chez nous. Fait avec beaucoup de professionnalisme et proprement. Je vous les recommande fortement. »", 
   },
   {
    name: 'Odette Lapierre',
    rating:5,
    review: "« Merci à Peinture Grande Distinction pour un travail haut de gamme de peinture et papier peint chez ma fille Michelle Beau.  Une équipe super efficace et professionnelle.  Nous vous recommandons chaudement Peinture Grande Distinction. »", 
   }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <Swiper className='container testimonials_container'
          // install Swiper modules
      modules={[Pagination, Autoplay]}
      spaceBetween={40}
      slidesPerView={1}
      autoplay={{
        delay:5800,
        disableOnInteraction:false
      }}
      centeredSlides={true}
      pagination={{ clickable: true }}>
        {
          data.map(({name, rating, review}, index)=>{
             return(
              <SwiperSlide key= {index} className='testimonial' autoPlay= {20}>
                   <p className='client_name'>{name}</p>
                   <p className='client_review'>{review}</p>
              <FaStar
                color={
                  rating >= 1 
                  ? "rgb(255,255,0)"
                  : "rgb(255,255,255)"
                }
              />
               <FaStar
                color={
                  rating >= 2
                  ? "rgb(255,255,0)"
                  : "rgb(255,255,255)"
                }
              />
               <FaStar
                color={
                 rating >= 3
                 ? "rgb(255,255,0)"
                 : "rgb(255,255,255)"
                }
              />
               <FaStar
                color={
                  rating >= 4
                  ? "rgb(255,255,0)"
                  : "rgb(255,255,255)"
                }
              />
               <FaStar
                color={
                  rating >= 5
                  ? "rgb(255,255,0)"
                  : "rgb(255,255,255)"
                }
              />
               </SwiperSlide>
             )    
           })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials
