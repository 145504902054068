import React from 'react'
import './header.css'
import HeaderImg from '../../assets/ImageTitleHeader.svg'

const Header = () => {
    return (
        <div className="header"> 
             {(window.location.pathname==='/equipe'&&<h1>Notre équipe</h1>)||
             (window.location.pathname==='/services'&&<h1>Nos services</h1>)||
             (window.location.pathname==='/realisations'&&<h1>Nos réalisations</h1>)||
             (window.location.pathname==='/contact'&&<h1>Contact</h1>)}
             <img src={HeaderImg} alt="" />
        </div>
    )
}
export default Header